import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import style from './projectThumb.module.css'
import Img from 'gatsby-image'
import { animationLayouts } from '../../styles/animationLayouts'

export const ProjectThumb = ({ node }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={animationLayouts.project(node.uid)}
        layoutId={animationLayouts.project(node.uid)}
        className={style.wrapper}>
        <motion.div className={style.ratioBox}>
          <Img
            className={style.img}
            imgStyle={{ width: '100%', height: '100%', objectFit: 'cover'}}
            fluid={node.data.cover_image.fluid}
          />
          <motion.div className={style.innerContainer}>
            <motion.div className={style.titleContainer}>
              <motion.h2 className={style.title}>{node.data.title.text}</motion.h2>
              <p className={style.plus}>+</p>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  )
};
