import React, { useState } from "react"
import { graphql } from 'gatsby'
import { Exhibitions } from "../components/exhibitions"
import { ProjectsList } from "../components/ProjectsList"
import { About } from "../components/About"
import { motion } from "framer-motion"
import cx from 'classnames'
import rightArrow from '../images/right-arrow.svg'

import style from '../styles/home.module.css'
import { animationLayouts } from "../styles/animationLayouts"

const IndexPage = ( { data: { allPrismicProject, allPrismicAbout, allPrismicExhibition, allPrismicProjectsOrdering }}) => {
  const [ about ] = allPrismicAbout.edges;
  const [sideBarOpen, setSideBarOpen] = useState(false)

  const projects = (
    <ProjectsList projects={allPrismicProject.edges} ordering={allPrismicProjectsOrdering} />
  )

  return (
    <motion.div className={style.siteContainer} layout>
      <About data={about.node.data} />
      <motion.div className={cx([style.mainContent, style.mainContentMobile])}>
        <motion.div layout layoutId={animationLayouts.leftColumn} className={cx([style.column, style.mainColumnMobile, style.columnLeft])}>
          <motion.div animate={{ opacity: 1, transition: { delay: .2 }}} className={style.contentContainer}>
            {projects}
          </motion.div>
        </motion.div>

        <motion.div layoutId={animationLayouts.rightColumn} className={cx([style.column, style.columnMobile, style.sideBarMobile])}>
          <button className={style.sidebarOpenerIcon} onClick={() => setSideBarOpen(!sideBarOpen)}>
            <img className={style.headerIcon} src={rightArrow} alt="Sidebar icon" />
          </button>
          <motion.div animate={{ opacity: 1, transition: { delay: .2 }}} className={cx([style.contentContainer, style.hideOnMobile])}>
            <Exhibitions exhibitions={allPrismicExhibition} />
          </motion.div>
        </motion.div>
        {sideBarOpen ? (
          <div className={style.translateContainer}>
            <Exhibitions onIconClick={() => setSideBarOpen(!sideBarOpen)} exhibitions={allPrismicExhibition} />
          </div>
        ) : null}
      </motion.div>
    </motion.div>
  )
}

export const indexQuery = graphql`
  query IndexQuery {
    allPrismicAbout {
      edges {
        node {
          data {
            bio {
              raw
            }
            details {
              raw
            }
            email {
              text
            }
            phone_number {
              text
            }
            title {
              text
            }
            cv {
              url
            }
          }
        }
      }
    }
    allPrismicProjectsOrdering {
      edges {
        node {
          data {
            projects {
              project {
                uid
                slug
                id
              }
            }
          }
        }
      }
    }
    allPrismicExhibition(sort: {fields: first_publication_date, order: DESC}, limit: 80) {
      nodes {
        id
        uid
        data {
          title {
            text
          }
          description {
            text
          }
          end_date
          location {
            text
          }
          link {
            link_type
            url
            uid
            slug
          }
          start_date
        }
      }
    }

    allPrismicProject(sort: {fields: first_publication_date, order: DESC}) {
      edges {
        node {
          uid
          id
          data {
            title {
              text
            }
            subtitle {
              text
            }
            description {
              html
            }
            cover_image {
              fluid(maxWidth: 1220, maxHeight: 800) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            pictures {
              picture {
                fluid(maxWidth: 1220, maxHeight: 800) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
