import {  motion } from 'framer-motion'
import { RichText } from 'prismic-reactjs'
import React, { useState } from 'react'
import { animationLayouts } from '../../styles/animationLayouts'
import style from './about.module.css'
import arrow from '../../images/arrow.svg'
const variants = {
  visible: { height: "auto", visibility: 'visible', opacity: 1 },
  hidden: { height: 0, visibility: 'hidden', opacity: 0 },
}

const arrowVariants = {
  open: { rotate: 0 },
  close: { rotate: 180 },
}

export const About = ({ data }) => {
  const [isOpen, setAbout] = useState(false)
  const toggleAbout = () => {
    setAbout(!isOpen)
  }

  return (
    <motion.div layoutId={animationLayouts.about} className={style.container}>
      <div className={style.topContainer}>
        <h1 className={style.title}>{data.title.text}</h1>
        <button onClick={toggleAbout}>
          <motion.img
            variants={arrowVariants}
            initial="open"
            animate={isOpen ? "close" : "open"}
            className={style.arrow}
            src={arrow}
            data-close={isOpen}
          />
        </button>
      </div>
      <motion.div
        initial="hidden"
        animate={isOpen ? "visible" : "hidden"}
        variants={variants}
        key="about-block"
        className={style.contentWrapper}
        data-visible={isOpen}
      >
        <motion.div className={style.description}>
          <RichText render={data.bio.raw} />
        </motion.div>
        <motion.div className={style.info}>
          <motion.div className={style.topDescription}>
            {data?.email?.text ? <a target='_blank' rel="noreferrer" href={`mailto:${data.email.text}`}>{data.email.text}</a> : null}
            {data?.phone_number?.text ? <a target='_blank' rel="noreferrer" href={`tel:${data.phone_number.text}`}>{data.phone_number.text}</a> : null}
            {data?.cv?.url ? <a target='_blank' rel="noreferrer" href={data.cv.url}>Download CV</a> : null}
          </motion.div>
          <motion.div className={style.details}>
            <RichText render={data.details.raw} />
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}
