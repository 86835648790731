import { compareAsc, format, isFuture } from 'date-fns';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react'
import rightArrow from '../images/right-arrow.svg'
import upcomingIcon from '../images/upcoming.svg'
import arrow from '../images/arrow.svg'
import style from '../styles/exhibitions.module.css'
import { groupBy } from '../utils'
import cx from 'classnames'

const makeTitle = (exhibition) => {
  if (exhibition?.data?.link) {
    if(exhibition?.data?.link?.link_type === "Document") {
      return (
        <Link className={style.logTitle} to={`/project/${exhibition?.data?.link?.uid}`}>
          {exhibition?.data?.title?.text}
        </Link>
      )
    } else {
      return (
        <a className={style.logTitle} href={exhibition.data.link.url} target="_blank" rel="noreferrer">
          {exhibition?.data?.title?.text}
        </a>
      )
    }
  } else {
    return (
      <h3 className={style.logTitle}>
        {exhibition?.data?.title?.text}
      </h3>
    )
  }
}

const exhibitionComponent = (exhibition) => {
  const startDate = new Date(exhibition?.data?.start_date);
  const endDate = exhibition?.data?.end_date ? new Date(exhibition?.data?.end_date) : null;

  const formattedDate = endDate
    ? `${format(startDate, 'dd MMM')} – ${format(endDate, 'dd MMM yyyy')}`
    : `${format(startDate, 'dd MMM yyyy')}`

  return (
    <div className={style.logInfo} key={`${exhibition?.id}-${exhibition?.uid}`}>
      <p className={style.logDate}>{formattedDate}</p>
      {makeTitle(exhibition)}
      <p className={style.logDescription}>{exhibition.data.description.text}</p>
      {exhibition?.data?.location && ( <p className={style.logLocation}>{exhibition?.data?.location?.text}</p> )}
    </div>
  )
}

const makeExhibitions = (exhibitions) => {
  const sortedExhibitions = exhibitions.nodes.slice().sort((a, b) => {
    return compareAsc(new Date(a?.data?.start_date), new Date(b?.data?.start_date))
  })
  const [pastLogs, futureLogs] = sortedExhibitions.reduce(([p, f], exhibition) => {
    if(isFuture(new Date(exhibition?.data?.start_date))) {
      f.push(exhibition)
    } else {
      p.push(exhibition)
    }

    return [p, f]
  }, [[], []])

  const groupedPastExhibitions = groupBy(pastLogs, exhibition => {
    const date = new Date(exhibition.data.start_date);
    return `${format(date, "MM/yy")}`
  });
  const groupedFutureExhibtions = groupBy(futureLogs, exhibition => {
    const date = new Date(exhibition.data.start_date);
    return `${format(date, "MM/yy")}`
  });

  const exhibitionsWithDate = (date, exhibitions) => (
    <div className={style.singleLog} key={`${date}`}>
      <div className={style.logGroupDate}>
        {date}
      </div>
      <div className={style.logElements}>
        {exhibitions.map(exhibitionComponent)}
      </div>
    </div>
  )

  const pastComponents = []
  const futureComponents = []

  for (let [date, exhibitions] of groupedPastExhibitions.entries()) {
    pastComponents.push(exhibitionsWithDate(date, exhibitions))
  }

  for (let [date, exhibitions] of groupedFutureExhibtions.entries()) {
    futureComponents.push(exhibitionsWithDate(date, exhibitions))
  }

  return [pastComponents.reverse(), futureComponents.reverse()];
}

export const Exhibitions = ({ exhibitions, onIconClick }) => {
  const [pastLogs, futureLogs] = makeExhibitions(exhibitions)
  return (
    <motion.div className={style.logsWrapper} layoutId="right-content">
      {futureLogs && futureLogs.length > 0
      ? (
        <>
        <div className={style.header}>
          {onIconClick ? (
            <button onClick={onIconClick}>
              <img className={cx([style.headerIcon, style.back])} src={arrow} alt="back" />
            </button>
          ) : (
            <img className={style.headerIcon} src={upcomingIcon} alt="upcoming events" />
          )}
          <h2 className={style.title}>Upcoming.</h2>
        </div>
        <div className={style.logContainer} data-border="true">
          {futureLogs}
        </div>
        </>
      )
      : null
      }
      <div className={style.header}>
        {onIconClick ? (
          <button onClick={onIconClick}>
            <img className={style.headerIcon} src={rightArrow} alt="back" />
          </button>
        ) : (
          <img className={style.headerIcon} src={rightArrow} alt="logs"  />
        )}

        <h2 className={style.title}>log.</h2>
      </div>
      <div className={style.logContainer}>
        {pastLogs}
      </div>
    </motion.div>
  )
}
