// import { Link } from 'prismic-reactjs';
import React from 'react'
import { ProjectThumb } from '../ProjectThumb'
import { Link } from 'gatsby'

export const ProjectsList = ({ projects = [], ordering }) => {
  const projectsByKey = projects.reduce((acc, project) => {
    return {
      ...acc,
      [project.node.uid]: project.node
    }
  }, {})

  if(ordering?.edges?.length > 0 && ordering?.edges[0]?.node?.data?.projects?.length > 0) {
    const projectsOrdering = ordering?.edges[0]?.node?.data?.projects

    return projectsOrdering.map(({ project }) => {
      return <Link key={`project-link-${project.uid}`} to={`/project/${project.uid}`}>
        <ProjectThumb node={projectsByKey[project.uid]} />
      </Link>
    })
  }

  return projects.map(({ node }) => {
    return (
      <Link key={`project-link-${node.uid}`} to={`/project/${node.uid}`}>
        <ProjectThumb node={node} />
      </Link>
    )
  })
};
